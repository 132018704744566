import request from '@/utils/request'
const baseURL = '/baseapi'
const ResidentApi = {
  ResidentList: '/admin/resident/list',
  ResidentInfo: '/admin/resident/residentInfo',
  SaveResident: '/admin/resident/saveResident',
  CheckTelephone: '/admin/resident/checkTelephone',
  Condition: '/admin/resident/condition',
  SaveResidentTag: '/admin/resident/saveResidentTag',
  DelResident: '/admin/resident/delResident',
  SearchResident: '/admin/resident/searchResident',
  ResidentCode: '/admin/resident/getCode'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function residentList (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.ResidentList,
    method: 'post',
    data: parameter
  })
}

export function residentInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.ResidentInfo,
    method: 'post',
    data: parameter
  })
}

export function saveResident (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.SaveResident,
    method: 'post',
    data: parameter
  })
}

export function checkTelephone (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.CheckTelephone,
    method: 'post',
    data: parameter
  })
}

export function residentCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function saveResidentTag (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.SaveResidentTag,
    method: 'post',
    data: parameter
  })
}

export function delResident (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.DelResident,
    method: 'post',
    data: parameter
  })
}

export function searchResident (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.SearchResident,
    method: 'post',
    data: parameter
  })
}

export function residentCode (parameter) {
  return request({
    baseURL: baseURL,
    url: ResidentApi.ResidentCode,
    method: 'post',
    data: parameter
  })
}
