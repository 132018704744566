<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号">
                <a-input placeholder="请输入手机号" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="住户姓名">
                <a-input placeholder="请输入住户姓名" v-model="queryParam.realName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="选择小区">
                  <a-select
                    v-model="queryParam.communityId"
                    placeholder="请选择小区"
                    @change="changeCommunity"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择楼宇">
                  <a-select
                    v-model="queryParam.buildingId"
                    placeholder="选择楼宇"
                    @change="changebuilding"
                  >
                    <a-select-option :key="0" :value="0">选择楼宇</a-select-option>
                    <a-select-option v-for="item in regionBuilding" :key="item.keyId" :value="item.id">
                      {{ item.buildingName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择单元">
                  <a-select
                    v-model="queryParam.unitId"
                    placeholder="选择单元"
                    @change="changeUnit"
                  >
                    <a-select-option :key="0" :value="0">选择单元</a-select-option>
                    <a-select-option v-for="item in regionUnit" :key="item.keyId" :value="item.id">
                      {{ item.unitName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择房屋">
                  <a-select
                    v-model="queryParam.roomId"
                    placeholder="选择房屋"
                  >
                    <a-select-option :key="0" :value="0">选择房屋</a-select-option>
                    <a-select-option v-for="item in regionHouse" :key="item.keyId" :value="item.id">
                      {{ item.houseName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addSpecial">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="specialList" slot-scope="text, record">
          <a-tag style="margin-bottom: 5px" color="cyan" v-for="(action, k) in record.special" :key="k">{{ action.typeName }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="editSpecial(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要解除房屋关系吗？" @confirm="delResidentApi(record.keyId, record.roomId)">
            <a>解除房屋</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item><a @click="editSpecialTag(record)">添加标签</a></a-menu-item>
              <!--a-menu-item><a @click="gotoAddFocus(record)">添加关注</a></a-menu-item-->
              <a-menu-item><a @click="gotoCodeInfo(record)">查看身份二维码</a></a-menu-item>
            </a-menu>
            <a>更多<a-icon type="down"/></a>
          </a-dropdown>
        </span>
      </s-table>
      <a-modal
        title="添加/编辑住户"
        :width="800"
        :destroyOnClose="true"
        v-model="specialVisible"
        @ok="saveResidentApi"
      >
        <a-form :form="form">
          <a-form-item
            style="margin-bottom: 0px;"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            :required="true"
            label="区域"
          >
            <a-row :gutter="24">
              <a-col :md="12" :sm="16">
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :validateStatus="checkParams.areaCodeStatus"
                  :help="checkParams.areaCodeStatusMsg"
                >
                  <a-cascader
                    :allowClear="false"
                    style="width:246px;"
                    :options="regionList"
                    placeholder="省-市-区(县)"
                    :field-names="{label: 'label', value: 'value', children: 'children' }"
                    @change="changeAddRegion"
                    v-decorator="['areaCode', {initialValue: addDefaultRegion, rules: [{required: true}]}]"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="16">
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :validateStatus="checkParams.communityIdStatus"
                  :help="checkParams.communityIdStatusMsg"
                >
                  <a-select
                    style="width:238px;"
                    placeholder="请选择小区"
                    v-model="special.communityId"
                    @change="changeAddCommunity"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item
            style="margin-bottom: 0px;"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            :required="true"
            label="房屋"
          >
            <a-row :gutter="24">
              <a-col :md="8" :sm="16">
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :validateStatus="checkParams.buildingIdStatus"
                  :help="checkParams.buildingIdStatusMsg"
                >
                  <a-select
                    style="width:150px;"
                    placeholder="请选择楼宇"
                    v-model="special.buildingId"
                    @change="changeAddBuilding"
                  >
                    <a-select-option :key="0" :value="0">请选择楼宇</a-select-option>
                    <a-select-option v-for="item in regionBuildingAdd" :key="item.keyId" :value="item.id">
                      {{ item.buildingName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="16">
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :validateStatus="checkParams.unitIdStatus"
                  :help="checkParams.unitIdStatusMsg"
                >
                  <a-select
                    style="width:150px;"
                    placeholder="请选择单元"
                    v-model="special.unitId"
                    @change="changeAddUnit"
                  >
                    <a-select-option :key="0" :value="0">请选择单元</a-select-option>
                    <a-select-option v-for="item in regionUnitAdd" :key="item.keyId" :value="item.id">
                      {{ item.unitName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="16">
                <a-form-item
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  :validateStatus="checkParams.houseNameStatus"
                  :help="checkParams.houseNameStatusMsg"
                >
                  <a-select
                    style="width:150px;"
                    placeholder="请选择房间"
                    v-model="special.roomId"
                    @change="changeAddHouse"
                  >
                    <a-select-option :key="0" :value="0">请选择房间</a-select-option>
                    <a-select-option v-for="item in regionHouseAdd" :key="item.keyId" :value="item.id">
                      {{ item.houseName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="手机号"
            :validateStatus="checkParams.telephoneStatus"
            :help="checkParams.telephoneStatusMsg"
          >
            <a-input
              placeholder="请输入手机号"
              v-decorator="['telephone', {initialValue: special.telephone, rules: [{required: true, validator: telephoneCheck, validateTrigger: 'change'}]}]"
              :maxLength="11"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="用户姓名"
            :validateStatus="checkParams.realNameStatus"
            :help="checkParams.realNameStatusMsg"
          >
            <a-input
              placeholder="请输入用户姓名"
              v-decorator="['realName', {initialValue: special.realName, rules: [{required: true, validator: realNameCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="邮箱"
          >
            <a-input
              placeholder="请输入邮箱"
              v-model="special.email"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="性别"
          >
            <a-radio-group
              v-model="special.gender"
              style="width: 100%"
            >
              <a-radio :value="0">未知</a-radio>
              <a-radio :value="1">男</a-radio>
              <a-radio :value="2">女</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea :rows="5" v-model="special.desc" placeholder="备注" />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="标签"
          >
            <a-col :md="8" :sm="6" v-for="(item, index) in specialList" :key="index">
              <a-checkbox
                :value="item.id"
                :checked="special.specialIdArr.indexOf(item.id) > -1 ? true : false"
                @change="specialTypeChange"
              >
                {{ item.typeName }}
              </a-checkbox>
            </a-col>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        :centered="true"
        title="打标签"
        :width="800"
        :destroyOnClose="true"
        v-model="tagVisible"
        @ok="saveResidentTagApi"
      >
        <a-form :form="tagform">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="标签"
          >
            <a-col :md="9" :sm="3" v-for="(item, index) in specialList" :key="index">
              <a-checkbox
                :value="item.id"
                :checked="special.specialIdArr.indexOf(item.id) > -1 ? true : false"
                @change="specialTypeChange"
              >
                {{ item.typeName }}
              </a-checkbox>
            </a-col>
          </a-form-item>
        </a-form>
      </a-modal>
      <see-qrcode ref="seeqrcode"></see-qrcode>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { regionCommunity } from '@/api/community'
import { regionList } from '@/api/region'
import { regionBuilding } from '@/api/building'
import { regionUnit } from '@/api/unit'
import { regionHouse } from '@/api/house'
import SeeQrcode from '@/views/resident/components/SeeQrcode'
import {
  checkTelephone,
  delResident,
  residentCondition,
  residentList,
  saveResident,
  saveResidentTag
} from '@/api/resident'
import { specialList } from '@/api/special'
const columns = [
  {
    title: '住户编号',
    dataIndex: 'keyId'
  },
  {
    title: '住户姓名',
    dataIndex: 'realName'
  },
  {
    title: '手机号',
    dataIndex: 'telephone'
  },
  {
    title: '性别',
    dataIndex: 'genderStr'
  },
  {
    title: '住址',
    dataIndex: 'address'
  },
  {
    title: '标签',
    dataIndex: 'special',
    scopedSlots: { customRender: 'specialList' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '200px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'UnitList',
  components: {
    STable,
    Ellipsis,
    STree,
    SeeQrcode
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      specialVisible: false,
      tagVisible: false,
      form: this.$form.createForm(this, { name: 'addResidentFrom' }),
      tagform: this.$form.createForm(this, { name: 'tagFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        realName: '',
        telephone: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0,
        roomId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return residentList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      checkParams: {
        houseNameStatus: 'success',
        houseNameStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        buildingIdStatus: 'success',
        buildingIdStatusMsg: null,
        unitIdStatus: 'success',
        unitIdStatusMsg: null,
        telephoneStatus: 'success',
        telephoneStatusMsg: null,
        realNameStatus: 'success',
        realNameStatusMsg: null
      },
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      addDefaultRegion: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      regionBuilding: [],
      regionBuildingAdd: [],
      houseList: [],
      regionUnit: [],
      regionUnitAdd: [],
      special: {
        specialIdArr: []
      },
      specialList: [],
      regionHouse: [],
      regionHouseAdd: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
    if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
      this.queryParam.communityId = this.$route.query.communityId
    }

    if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
      this.queryParam.buildingId = this.$route.query.buildingId
    }

    if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
      this.queryParam.unitId = this.$route.query.unitId
    }

    if (this.$route.query.roomId !== 0 && this.$route.query.roomId !== undefined && this.$route.query.roomId !== '') {
      this.queryParam.roomId = this.$route.query.roomId
    }

    if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0 || this.queryParam.roomId !== 0) {
      this.residentConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId, this.queryParam.roomId)
    }
    // 标签
    this.specialListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'residentList') {
        this.queryParam = {
          realName: '',
          telephone: '',
          communityId: 0,
          buildingId: 0,
          unitId: 0,
          areaCode: 0,
          roomId: 0
        }
        this.defaultRegion = []
        this.regionListApi()
        if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
          this.queryParam.communityId = this.$route.query.communityId
        }

        if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
          this.queryParam.buildingId = this.$route.query.buildingId
        }

        if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
          this.queryParam.unitId = this.$route.query.unitId
        }

        if (this.$route.query.roomId !== 0 && this.$route.query.roomId !== undefined && this.$route.query.roomId !== '') {
          this.queryParam.roomId = this.$route.query.roomId
        }

        if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0 || this.queryParam.roomId !== 0) {
          this.residentConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId, this.queryParam.roomId)
        } else {
          this.regionCommunity = []
          this.regionBuilding = []
          this.regionUnit = []
          this.regionHouse = []
        }
        // 标签
        this.specialListApi()
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    specialListApi () {
      const _this = this
      specialList().then((res) => {
        if (res.errorCode === 0) {
          _this.specialList = res.result.data
          console.log('specialList', _this.specialList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    residentConditionApi (communityId, buildingId, unitId, roomId) {
      const _this = this
      residentCondition({ communityId: communityId, buildingId: buildingId, unitId: unitId, roomId: roomId }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.regionCommunity
          _this.defaultRegion = res.result.defaultRegion
          _this.queryParam.communityId = res.result.defaultCommunityId
          _this.queryParam.buildingId = res.result.defaultBuildingId
          _this.queryParam.unitId = res.result.defaultUnitId
          _this.regionBuilding = res.result.regionBuilding
          _this.regionUnit = res.result.regionUnit
          _this.regionHouse = res.result.regionHouse
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    telephoneCheck (rule, value, callback) {
      const _this = this
      _this.special.telephone = value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(value)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        callback(new Error('手机号格式不正确'))
      }
    },
    realNameCheck (rule, value, callback) {
      const _this = this
      _this.special.realName = value.trim()
      if (_this.special.realName !== '') {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      } else {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入用户姓名'
        callback(new Error('请输入用户姓名'))
      }
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        realName: '',
        telephone: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0,
        roomId: 0
      }
    },
    resetCheck () {
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.houseNameStatus = 'success'
      this.checkParams.houseNameStatusMsg = null
      this.checkParams.communityIdStatus = 'success'
      this.checkParams.communityIdStatusMsg = null
      this.checkParams.buildingIdStatus = 'success'
      this.checkParams.buildingIdStatusMsg = null
      this.checkParams.unitIdStatus = 'success'
      this.checkParams.unitIdStatusMsg = null
      this.checkParams.houseNameStatus = 'success'
      this.checkParams.houseNameStatusMsg = null
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.realNameStatus = 'success'
      this.checkParams.realNameStatusMsg = null
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.areaCode = e[2] ? e[2] : 0
      this.queryParam.communityId = 0
      if (this.queryParam.areaCode > 0) {
        this.regionCommunityApi(this.queryParam.areaCode, 0)
      } else {
        this.regionCommunity = []
      }
      this.regionBuilding = []
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changeCommunity (e) {
      this.queryParam.communityId = e
      this.regionBuildingApi(this.queryParam.communityId, 0)
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changebuilding (e) {
      this.queryParam.buildingId = e
      this.regionUnitApi(this.queryParam.buildingId, 0)
      this.queryParam.unitId = 0
      this.regionHouse = []
      this.queryParam.roomId = 0
    },
    changeUnit (e) {
      this.queryParam.unitId = e
      this.regionHouseApi(this.queryParam.unitId, 0)
      this.queryParam.roomId = 0
    },
    regionBuildingApi (communityId, type) {
      const _this = this
      regionBuilding({ communityId: communityId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionBuilding = res.result.data
          } else {
            _this.regionBuildingAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionUnitApi (buildingId, type) {
      const _this = this
      regionUnit({ buildingId: buildingId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionUnit = res.result.data
          } else {
            _this.regionUnitAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionHouseApi (unitId, type) {
      const _this = this
      regionHouse({ unitId: unitId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionHouse = res.result.data
          } else {
            _this.regionHouseAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.special.provinceId = e[0] ? e[0] : 0
      this.special.cityId = e[1] ? e[1] : 0
      this.special.areaCode = e[2] ? e[2] : 0

      if (this.special.areaCode === '' || this.special.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }

      this.regionCommunityApi(this.special.areaCode, 1)
      this.special.communityId = 0
      this.special.buildingId = 0
      this.special.unitId = 0
      this.special.roomId = 0
      this.regionBuildingAdd = []
      this.regionUnitAdd = []
      this.regionHouseAdd = []
    },
    changeAddCommunity (e) {
      console.log(e)
      this.special.communityId = e
      if (this.special.communityId === '' || this.special.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
      this.regionBuildingApi(this.special.communityId, 1)
      this.special.buildingId = 0
      this.special.unitId = 0
      this.special.roomId = 0
      this.regionUnitAdd = []
      this.regionHouseAdd = []
    },
    changeAddBuilding (e) {
      console.log(e)
      this.special.buildingId = e
      console.log('changeAddBuilding', this.special.buildingId)
      if (this.special.buildingId === '' || this.special.buildingId === 0 || this.special.buildingId === '0') {
        this.checkParams.buildingIdStatus = 'error'
        this.checkParams.buildingIdStatusMsg = '请选择楼宇'
        console.log('changeAddBuilding', this.special.buildingId)
      } else {
        this.checkParams.buildingIdStatus = 'success'
        this.checkParams.buildingIdStatusMsg = null
      }
      this.regionUnitApi(this.special.buildingId, 1)
      this.special.unitId = 0
      this.special.roomId = 0
      this.regionHouseAdd = []
    },
    changeAddUnit (e) {
      console.log(e)
      this.special.unitId = e
      if (this.special.unitId === '' || this.special.unitId === 0) {
        this.checkParams.unitIdStatus = 'error'
        this.checkParams.unitIdStatusMsg = '请选择单元'
      } else {
        this.checkParams.unitIdStatus = 'success'
        this.checkParams.unitIdStatusMsg = null
      }
      this.regionHouseApi(this.special.unitId, 1)
      this.special.roomId = 0
    },
    changeAddHouse (e) {
      console.log(e)
      this.special.roomId = e
      if (this.special.roomId === '' || this.special.roomId === 0) {
        this.checkParams.houseNameStatus = 'error'
        this.checkParams.houseNameStatusMsg = '请选择房间'
      } else {
        this.checkParams.houseNameStatus = 'success'
        this.checkParams.houseNameStatusMsg = null
      }
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    addSpecial () {
      this.special.keyId = 0
      this.special.id = ''
      this.special.address = ''
      this.special.areaCode = 0
      this.special.communityId = 0
      this.special.buildingId = 0
      this.special.unitId = 0
      this.special.roomId = 0
      this.special.telephone = ''
      this.special.email = ''
      this.special.realName = ''
      this.special.desc = ''
      this.special.gender = 0
      this.special.specialIdArr = []
      this.resetCheck()
      this.addDefaultRegion = []
      this.regionCommunityAdd = []
      this.regionBuildingAdd = []
      this.regionUnitAdd = []
      this.regionHouseAdd = []
      this.specialVisible = true
    },
    editSpecial (record) {
      this.special = Object.assign({}, record)
      console.log('editSpecial', this.special)
      this.addDefaultRegion = [record.provinceId, record.cityId, record.areaCode]
      this.regionCommunityApi(record.areaCode, 1)
      this.regionBuildingApi(record.communityId, 1)
      this.regionUnitApi(record.buildingId, 1)
      this.regionHouseApi(record.unitId, 1)
      this.resetCheck()
      this.specialVisible = true
    },
    specialTypeChange (e) {
      console.log('specialTypeChange', e)
      if (e.target.checked) {
        this.special.specialIdArr.push(e.target.value)
      } else {
        var index = this.special.specialIdArr.indexOf(e.target.value)
        if (index > -1) {
          this.special.specialIdArr.splice(index, 1)
        }
      }
    },
    saveResidentApi () {
      const _this = this
      _this.dataParams.keyId = _this.special.keyId ? _this.special.keyId : 0
      _this.dataParams.realName = _this.special.realName
      _this.dataParams.telephone = _this.special.telephone
      _this.dataParams.communityId = _this.special.communityId
      _this.dataParams.buildingId = _this.special.buildingId
      _this.dataParams.unitId = _this.special.unitId
      _this.dataParams.areaCode = _this.special.areaCode
      _this.dataParams.roomId = _this.special.roomId
      _this.dataParams.gender = _this.special.gender
      _this.dataParams.email = _this.special.email
      _this.dataParams.desc = _this.special.desc
      _this.dataParams.specialIdArr = _this.special.specialIdArr
      console.log(_this.dataParams)

      if (_this.dataParams.areaCode === '' || _this.dataParams.areaCode === 0 || _this.dataParams.areaCode === '请选择区域') {
        _this.checkParams.areaCodeStatus = 'error'
        _this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.areaCodeStatus = 'success'
        _this.checkParams.areaCodeStatusMsg = null
      }

      if (_this.dataParams.communityId === '' || _this.dataParams.communityId === 0) {
        _this.checkParams.communityIdStatus = 'error'
        _this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        _this.checkParams.communityIdStatus = 'success'
        _this.checkParams.communityIdStatusMsg = null
      }

      if (_this.dataParams.buildingId === '' || _this.dataParams.buildingId === 0) {
        _this.checkParams.buildingIdStatus = 'error'
        _this.checkParams.buildingIdStatusMsg = '请选择楼宇'
        return false
      } else {
        _this.checkParams.buildingIdStatus = 'success'
        _this.checkParams.buildingIdStatusMsg = null
      }

      if (_this.dataParams.unitId === '' || _this.dataParams.unitId === 0) {
        _this.checkParams.unitIdStatus = 'error'
        _this.checkParams.unitIdStatusMsg = '请选择单元'
        return false
      } else {
        _this.checkParams.unitIdStatus = 'success'
        _this.checkParams.unitIdStatusMsg = null
      }

      if (_this.dataParams.roomId === '' || _this.dataParams.roomId === 0) {
        _this.checkParams.houseNameStatus = 'error'
        _this.checkParams.houseNameStatusMsg = '请选择房间'
        return false
      } else {
        _this.checkParams.houseNameStatus = 'success'
        _this.checkParams.houseNameStatusMsg = null
      }

      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.dataParams.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        return false
      }

      if (_this.dataParams.realName === '') {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入住户姓名'
        return false
      } else {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      }

      checkTelephone(_this.dataParams).then((resc) => {
        console.log('checkTelephone', resc)
        if (resc.errorCode === 0) {
          saveResident(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              if (_this.dataParams.keyId > 0) {
                _this.$message.success('更新住户成功')
              } else {
                _this.$message.success('添加住户成功')
              }
              _this.specialVisible = false
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          _this.checkParams.telephoneStatus = 'error'
          _this.checkParams.telephoneStatusMsg = resc.errorMsg
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    editSpecialTag (record) {
      this.special = Object.assign({}, record)
      this.tagVisible = true
    },
    saveResidentTagApi () {
      const _this = this
      _this.dataParams.keyId = _this.special.keyId ? _this.special.keyId : 0
      _this.dataParams.specialIdArr = _this.special.specialIdArr
      saveResidentTag(_this.dataParams).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.tagVisible = false
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    delResidentApi (id, roomId) {
      const _this = this
      delResident({ keyId: id, roomId: roomId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gotoAddFocus (data) {
      this.$router.push({ path: '/resident/add-focus?focusId=0&residentKeyId=' + data.keyId + '&residentId=' + data.id })
    },
    gotoCodeInfo (data) {
      var info = {
        areaCode: data.areaCode,
        communityId: data.communityId,
        ownerId: data.id,
        codeType: 'JuMin'
      }
      this.$refs.seeqrcode.ininData(info)
    }
  }
}
</script>
