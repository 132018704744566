<template>
  <a-modal
    title="身份二维码"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelForm"
    :footer="null"
  >
    <div style="text-align: center">
      <a-spin :spinning="loading">
        <img :src="codeInfo.codeUrl" />
      </a-spin>
    </div>
  </a-modal>
</template>
<script>
import { residentCode } from '@/api/resident'
export default {
  name: 'ClassifyInfo',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      queryParam: {
        areaCode: 0,
        communityId: '',
        ownerId: '',
        codeType: ''
      },
      codeInfo: {
        codeUrl: ''
      }
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.queryParam.areaCode = data.areaCode
      this.queryParam.communityId = data.communityId
      this.queryParam.ownerId = data.ownerId
      this.queryParam.codeType = data.codeType
      this.saveDataApi()
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      _this.loading = true
      residentCode(_this.queryParam).then((res) => {
        _this.codeInfo = res.result.codeInfo
        _this.loading = false
        console.log(res)
      }).catch((err) => {
        _this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
